/*!
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'index' as themes;

// Instead of creating a theme with mat-dark-theme, we will create our own theme-creating function that lets us apply our own foreground and
// background palettes as discussed in https://github.com/angular/components/issues/6244#issuecomment-1017629612.

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: themes.m2-define-palette(themes.$devintent-primary-palette);
$app-accent: themes.m2-define-palette(themes.$devintent-accent-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: themes.m2-define-palette(themes.$devintent-warn-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-light-theme: themes.devintent-define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: themes.$devintent-custom-typography-config,
    density: 0,
  )
);
