/*!
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
@use '@angular/material' as mat;
@use 'variables';
@use 'typography';
@use 'theme-light';
@use 'app/about/about.theme';
@use 'app/nav/nav.theme';
@use 'app/footer/footer.theme';
@use 'app/blog/landing/landing.theme';
@use 'app/blog/author/author.theme';
@use 'app/blog/article-content/article-content.theme';
@use 'app/page-not-found/page-not-found.theme';
@use 'app/contact-form/contact-form.theme.scss';
@use 'app/team/team.theme.scss';
@use 'app/contact-form/thank-you/thank-you.theme.scss';
@use 'app/technology-cards/technology-cards.theme.scss';
@use 'app/shared/blog-post-card/blog-post-card.theme.scss';
@use 'styles/mat-components/index.scss' as mat-custom;
@use 'app/blog/category-filter/category-filter.theme.scss';
@use 'app/blog/rss-button/rss-button.theme.scss';
@use 'app/contact-form/contact-info/contact-info.theme.scss';
@use 'app/angularjs/angularjs-landing/angularjs-landing.theme.scss';
@use 'app/shared/theme-toggle/theme-toggle.theme.scss';
@use 'app/careers/job-posting-content/job-posting-content.theme.scss';
@use 'app/careers/job-posting/job-posting.theme.scss';
@use 'app/careers/job-application-form/job-application-form.theme.scss';
@use 'app/careers/careers-landing/careers-landing.theme.scss';
@use 'app/careers/job-interest-form/job-interest-form.theme.scss';
@use 'app/blog/article/article.theme.scss';

@include mat.core();
@include mat.typography-hierarchy(typography.$devintent-custom-typography-config);

// Angular Material theming. Include theme styles for core and each component used in the app.
@include mat.core-theme(theme-light.$app-light-theme);
@include mat.toolbar-theme(theme-light.$app-light-theme);
@include mat.tabs-theme(theme-light.$app-light-theme);
@include mat.icon-theme(theme-light.$app-light-theme);
@include mat.tooltip-theme(theme-light.$app-light-theme);
@include mat.snack-bar-theme(theme-light.$app-light-theme);
@include mat.sidenav-theme(theme-light.$app-light-theme);
@include mat.list-theme(theme-light.$app-light-theme);
@include mat.divider-theme(theme-light.$app-light-theme);
@include mat.button-theme(theme-light.$app-light-theme);
@include mat.card-theme(theme-light.$app-light-theme);
@include mat.progress-spinner-theme(theme-light.$app-light-theme);
@include mat.chips-theme(theme-light.$app-light-theme);
@include mat.form-field-theme(theme-light.$app-light-theme);
@include mat.select-theme(theme-light.$app-light-theme);
@include mat.menu-theme(theme-light.$app-light-theme);
@include mat.button-toggle-theme(theme-light.$app-light-theme);
@include mat.paginator-theme(theme-light.$app-light-theme);

// Application theming
@include nav.dev-nav-theme(theme-light.$app-light-theme);
@include about.dev-about-theme(theme-light.$app-light-theme);
@include footer.dev-footer-theme(theme-light.$app-light-theme);
@include landing.dev-blog-landing-theme(theme-light.$app-light-theme);
@include author.dev-author-theme(theme-light.$app-light-theme);
@include page-not-found.dev-page-not-found-theme(theme-light.$app-light-theme);
@include article-content.dev-article-content-theme(theme-light.$app-light-theme);
@include contact-form.dev-contact-form-theme(theme-light.$app-light-theme);
@include team.dev-team-theme(theme-light.$app-light-theme);
@include thank-you.dev-thank-you-theme(theme-light.$app-light-theme);
@include technology-cards.dev-technology-cards(theme-light.$app-light-theme);
@include blog-post-card.dev-blog-post-card-theme(theme-light.$app-light-theme);
@include category-filter.dev-category-filter-theme(theme-light.$app-light-theme);
@include rss-button.dev-rss-button-theme(theme-light.$app-light-theme);
@include contact-info.dev-contact-info-theme(theme-light.$app-light-theme);
@include angularjs-landing.dev-angularjs-landing-theme(theme-light.$app-light-theme);
@include theme-toggle.dev-theme-toggle-theme(theme-light.$app-light-theme);
@include job-posting-content.dev-job-posting-content-theme(theme-light.$app-light-theme);
@include job-posting.dev-job-posting-theme(theme-light.$app-light-theme);
@include job-application-form.dev-job-application-form-theme(theme-light.$app-light-theme);
@include careers-landing.dev-careers-landing-theme(theme-light.$app-light-theme);
@include job-interest-form.dev-job-interest-form-theme(theme-light.$app-light-theme);
@include article.dev-article-theme(theme-light.$app-light-theme);

// Customizing Angular Material components theme
@include mat-custom.mat-tooltip-custom-theme(theme-light.$app-light-theme);
@include mat-custom.mat-select-custom-theme(theme-light.$app-light-theme);
@include mat-custom.mat-snackbar-custom-theme(theme-light.$app-light-theme);
@include mat-custom.mat-form-field-custom-theme(theme-light.$app-light-theme);
@include mat-custom.mat-card-custom-theme(theme-light.$app-light-theme);
@include mat-custom.mat-button-custom-theme(theme-light.$app-light-theme);

/* You can add global styles to this file, and also import other global style files */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: variables.$devintent-grey;
}
.mat-mdc-button-base {
  text-transform: uppercase;
}
a {
  text-decoration-color: variables.$devintent-blue;
  color: variables.$devintent-blue;
  text-wrap: nowrap;
}
a:visited {
  text-decoration-color: mat.m2-get-color-from-palette(mat.$m2-deep-purple-palette, A200);
  color: mat.m2-get-color-from-palette(mat.$m2-deep-purple-palette, A200);
}
code {
  background: #f4f4f4;
}
